@import "../node_modules/modern-css-reset/dist/reset";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

// Some bit.B Styling Variables
$accent-color:              rgb(20, 143, 220);
$accent-color--darker:      rgb(16, 117, 180);

$text-color:                rgb(39, 44, 45);
$white-text-color:          rgb(255, 255, 255);
$background-color:          rgb(230, 234, 234);

$font-family:               'Source Sans Pro', Verdana, sans-serif;

// Some base styles
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 3rem;
  font-family: $font-family;
  font-size: 1rem;
  color: $text-color;
  background: $background-color;
}

h1 {
  margin: 0;
}

mark {
  padding: 0.1em;
  background: transparentize($accent-color, 0.85);
}

a {
  color: $accent-color--darker;
}

// Some "Objects"
.box {
  padding: 2rem;
  background: $white-text-color;
}

.stack {
  & > * + * {
    margin-top: 2rem;
  }
}

.wrapper {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

// Some "Components"
.logo {
  width: 90px;
  height: auto;
}

.button {
  display: inline-flex;
  align-items: center;
  position: relative;
  vertical-align: middle;
  outline: none;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 0.75rem;
  transform-origin: 50% 50%;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  color: $white-text-color;
  background: $accent-color;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white-text-color;
    background: $accent-color--darker;
  }
}

// Some utilities
.text-xl {
  font-size: 2rem;
}

.text-lg {
  font-size: 1.35rem;
}

.text-sm {
  font-size: 0.8rem;
  letter-spacing: 0.025em;
}

.mb {
  margin-bottom: 1rem;
}

.mb-lg {
  margin-bottom: 2rem;
}

.mb-sm {
  margin-bottom: 0.5rem;
}

.flex {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1rem;
  }
}
